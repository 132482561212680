import { useEffect, useRef, useState } from 'react'

let startX
let startY
const delta = 6

export default function useComponentVisible(initialIsVisible) {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
    const ref = useRef(null)

    const handleMouseDown = (event) => {
        startX = event.pageX
        startY = event.pageY
    }

    const handleMouseUp = (event) => {
        const diffX = Math.abs(event.pageX - startX)
        const diffY = Math.abs(event.pageY - startY)
        if (diffX < delta && diffY < delta && ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleMouseDown, true)
        document.addEventListener('mouseup', handleMouseUp, true)

        return () => {
            document.removeEventListener('mousedown', handleMouseDown, true)
            document.removeEventListener('mouseup', handleMouseUp, true)
        }
    })

    return { ref, isComponentVisible, setIsComponentVisible }
}
