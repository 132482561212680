import React from 'react'
import _ from 'lodash'
import { connectCurrentRefinements } from 'react-instantsearch-dom'
import { generateId } from '@frontastic/common'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { ReactComponent as CloseIcon } from '../../../../../../icons/sport2000-icons/close.svg'

type Props = {
    items: any[]
    refine: (data: any) => void
    customClassName?: any
} & WrappedComponentProps

const Refinements: React.FC<Props> = (props) => {
    const {
        items, refine, intl, customClassName,
    } = props

    if (!items) {
        return null
    }

    const hasShowResetFilter = () => {
        let count = 0
        items.forEach((item) => {
            if (item.attribute === 'price') {
                count += 1
                return
            }

            if (_.isArray(item.currentRefinement)) {
                count += item.currentRefinement.length
            }
        })

        return count > 1
    }

    return (
        <div className={`filter-algolia--refinemets ${customClassName}`}>
            {hasShowResetFilter() && (
                <div
                    className={'btn-clear-refinements is--all'}
                >
                    <FormattedMessage
                        id={'filters.clearAll'}
                    />

                    <button
                        type={'button'}
                        aria-label={intl.formatMessage({ id: 'filters.clearAll' })}
                    >
                        <CloseIcon
                            width={10}
                            height={10}
                            onClick={() => refine(items)}
                        />
                    </button>
                </div>
            )}
            {items.map((item: any) => {
                if (item.attribute === 'price') {
                    return (
                        <div
                            key={item.attribute}
                            className={'btn-clear-refinements'}
                        >
                            {item.currentRefinement.min / 100}
                            <FormattedMessage
                                id={'filters.unit'}
                            />
                            {' '}
                            -
                            {' '}
                            {item.currentRefinement.max / 100}
                            <FormattedMessage
                                id={'filters.unit'}
                            />

                            <button type={'button'} aria-label={intl.formatMessage({ id: 'filters.unit' })}>
                                <CloseIcon
                                    width={10}
                                    height={10}
                                    onClick={() => refine(item.value)}
                                />
                            </button>
                        </div>
                    )
                }

                return (
                    item.items && item.items.map((subItem, i) => (
                        <div
                            key={subItem + generateId()}
                            className={'btn-clear-refinements'}
                        >
                            {subItem.label}

                            <button
                                type={'button'}
                                aria-label={intl.formatMessage({ id: 'filters.clear' })}
                                onClick={() => refine(subItem.value)}
                            >
                                <CloseIcon width={10} height={10} />
                            </button>
                        </div>
                    ))
                )
            })}
        </div>
    )
}

export default connectCurrentRefinements(injectIntl(Refinements))
