import React, { useEffect, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import classnames from 'classnames'
import MenuItem from './MenuItem'
import { ReactComponent as IconArrowDown } from '../../../../../icons/sport2000-icons/arrow-down.svg'
import { ReactComponent as IconClose } from '../../../../../icons/sport2000-icons/close.svg'
import IconButton from '../../../atoms/button/IconButton'
import SlideMenu from '../SlideMenu'
import CategoryService from '../../../../services/category'

type Props = {
    currentCategory: any,
    items: any,
    intl: any,
    facetCategory: Object
    node: any,
    displaySidebarByResult: boolean
    selectedCategorySidebar?: string
}

const SidebarMenu: React.FC<Props> = ({
    items, facetCategory, intl, currentCategory, node, displaySidebarByResult, selectedCategorySidebar = ''
}) => {
    const isMobile = useDeviceType() === 'mobile'

    if (!items || !items.length) {
        return null
    }

    const [isOpen, setIsOpen] = useState(false)
   

    useEffect(() => {
        if (isMobile) {
            const element: any = document.getElementById('active-category-sidebar')
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }, [])

    return (
        <div className={'menu-algolia'}>
            {/* <div className={'menu-algolia--dropdown'}>
                <p className={'font-bold mb-2'}>
                    <FormattedMessage id={'categorySidebar.dropdownTitle'} />
                </p>
                <button
                    type={'button'}
                    aria-label={''}
                    onClick={() => setIsOpen(true)}
                >
                    <span>{currentCategory.name}</span>
                    <IconArrowDown width={16} height={16} />
                </button>
            </div> */}

            <div className={classnames('menu-algolia--container', {
                active: isOpen,
            })}
            >
                <div className={'menu-algolia--dropdown-title'}>
                    <h4 className={'mb-0'}>
                        <FormattedMessage id={'categorySidebar.menuTitle'} />
                    </h4>
                    <IconButton
                        type={'button'}
                        ariaLabel={intl.formatMessage({ id: 'categorySidebar.close' })}
                        icon={<IconClose width={20} height={20} />}
                        onClick={() => setIsOpen(false)}
                    />
                </div>
                <ul className={'menu-algolia--wrapper'}>
                    {items.map((item) => {
                        const isCategorySidebarActive = CategoryService.isCategoryActive(item, currentCategory, selectedCategorySidebar)

                        return (
                          <li
                            key={item.id}
                            id={classnames({
                                'active-category-sidebar': isCategorySidebarActive && isMobile,
                            })}
                          >
                              <MenuItem
                                key={item.id}
                                item={item}
                                facetCategory={facetCategory}
                                node={node}
                                displaySidebarByResult={displaySidebarByResult}
                                isCategorySidebarActive={isCategorySidebarActive}
                              />
                          </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    )
}

export default injectIntl(SidebarMenu)
