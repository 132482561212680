import React from 'react'
import { connectRefinementList } from 'react-instantsearch-dom'
import classnames from 'classnames'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import { useSelector } from 'react-redux'
import PanelButton from '../../PanelButton'
import ValuesPanel from '../../ValuesPanel'
import useComponentVisible from '../../../../../../utils/hooks/UseComponentVisible'
import { List } from './List'
import AttributeTranslationService from '../../../../../../services/algolia/AttributeTranslationService'

export type Props = {
    attribute: string
    algoliaIndexName?: string
    index: number
    isLogo?: boolean
    isActiveFilter?: boolean
} & any

const Select: React.FC<Props> = ({
    items,
    refine,
    attribute,
    currentRefinement,
    index,
    isLogo,
    isActiveFilter,
    algoliaIndexName,
}: Props) => {
    const activeFilter = useSelector((state) => state['product-list-algolia'].openFilter)
    const hasShowMore = useSelector((state) => state['product-list-algolia'].showMore)
    const isOpen = activeFilter === attribute
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(isOpen)
    const isDesktop = useDeviceType() === 'desktop'
    const showSearchBar = AttributeTranslationService.showSearchBarFilter(attribute)

    if (!items || !items.length) {
        return null
    }

    return (
        <div
            ref={ref}
            className={classnames('filter-algolia--panel-wrapper', {
                hidden: hasShowMore && index >= 5 && isDesktop,
            })}
        >
            <PanelButton
                attribute={attribute}
                isOpen={isOpen}
                isComponentVisible={isComponentVisible}
                setIsComponentVisible={setIsComponentVisible}
                isActiveFilter={isActiveFilter}
            />

            {isOpen && isComponentVisible && (
                <ValuesPanel
                    className={classnames({
                        hidden: !isOpen && !isComponentVisible,
                    })}
                >
                    <List
                        items={items}
                        refine={refine}
                        algoliaIndexName={algoliaIndexName}
                        attribute={attribute}
                        isLogo={isLogo}
                        showSearchBar={showSearchBar}
                    />
                </ValuesPanel>
            )}
        </div>
    )
}

export default connectRefinementList(Select)
