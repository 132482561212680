import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import BaseDotted from '../../../atoms/dotted/BaseDotted'
import { DottedFadebarVariantA5mm, DottedFadebarVariantB5mm } from '../../../atoms/dotted'

const Categorylayer = ({
    name, headline, backgroundColor,
}) => {
    const renderDottedBar = () => (
        <div className={'teaser--dotted-bar'}>
            <BaseDotted
                dottedSVG={<DottedFadebarVariantA5mm fill={'var(--color-black-10)'} />}
                height={14}
                isBottom
                isRotate
                from={'right'}
            />
            <BaseDotted
                dottedSVG={<DottedFadebarVariantB5mm fill={backgroundColor} />}
                height={14}
                isBottom
                isRotate
            />
        </div>
    )

    const urlBrandDetail = typeof window !== 'undefined'
                           ? window.location.pathname.match(/(\/brands)/) || window.location.pathname.match(/(\/marken)/)
                           : false

    return (
        <>
            {!urlBrandDetail ? (
                <div className={'category-layer-wrapper'}>
                    {renderDottedBar()}
                    <div className={'category-layer o-container'}>
                        <h1 className={'headline'}>{name}</h1>
                    </div>
                </div>
            ) : (
                <div className={'category-layer-brand-detail'}>
                    {headline ? (
                        <h2 className={'px-4 py-2 mb-0 lg:px-0 lg:py-0'}>
                            <span>{headline}</span>
                        </h2>
                    ) : (
                        <h2 className={'px-4 py-2 mb-0 lg:px-0 lg:py-0'}>
                            <FormattedMessage id={'brandDetail.headlineProductList'} />
                            {name}
                        </h2>
                    )}
                </div>
            )}
        </>
    )
}

Categorylayer.propTypes = {
    name: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string,
}

export default Categorylayer
