import { IntlShape } from 'react-intl'
import { useSelector } from 'react-redux'
import FilterService from './FilterService'
import { FilterTypeEnum } from '../../patterns/organisms/Algolia/Filter/FilterTypeEnum'

export default class AttributeTranslationService {
    private static readonly translateKeywords = [
        'size',
        'price',
        'brand',
        'color',
        'gender_categories',
        'category_names',
        'att_prod_gender',
        'att_text_main_material',
        'services',
        'logos',
    ]

    public static translateLabel(
        attribute: string, label: string, intl: IntlShape,
    ): string {
        return label.replace(
            new RegExp(attribute, 'g'),
            this.translateAttributeName({ attribute, intl, projectConfigTransKey: 'algoliaTranslation' }),
        )
    }

    public static translateFilterValues(
        attribute: string, value: string, intl: IntlShape,
    ): string {
        switch (FilterService.mapFilterAttribute(attribute)) {
            case FilterTypeEnum.COLOR:
                return FilterService.mapAndTranslateFilterColor(value, intl).translatedLabel
            default:
                return value
        }
    }

    public static translateAttributeName(
        { attribute, intl, projectConfigTransKey }: { attribute: string, intl: IntlShape, projectConfigTransKey: string },
    ): string {
        const context = useSelector((state: any) => state.app.context || {})
        const algoliaTranslations = context.projectConfiguration[projectConfigTransKey]
        if (algoliaTranslations) {
            const filterLabel = algoliaTranslations.filter((item) => item.name === attribute)
            if (filterLabel.length) {
                return filterLabel[0].label[context.locale]
            }
        }

        const attributeKeys = attribute.split(/[._]/)
        const keywordIntersection = attributeKeys.filter(
            (key) => AttributeTranslationService.translateKeywords.includes(key),
        )

        if (keywordIntersection.length === 0) {
            return attributeKeys.at(-1) || attribute
        }

        return intl.formatMessage({
            id: `filters.${keywordIntersection[0]}`,
        })
    }

    public static showSearchBarFilter(attribute: string) {
        const context = useSelector((state: any) => state.app.context || {})
        const algoliaTranslations = context.projectConfiguration.algoliaTranslation

        if (algoliaTranslations && algoliaTranslations.length > 0) {
            const filterLabel = algoliaTranslations.filter((item) => item.name === attribute)
            if (filterLabel.length && filterLabel[0].showSearchBar) {
                return true
            }
        }

        return false
    }

    public static translateFlag(flag: string, intl: IntlShape): string {
        return intl.formatMessage({
            id: `productList.attributeTranslations.${flag}`,
        })
    }
}
