import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { Hit } from 'react-instantsearch-core'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as RunningExpertLogo } from '../../../../../../../../icons/sport2000-icons/RunningExperts_CMYK.svg'
import { ReactComponent as TeamsportExpertLogo } from '../../../../../../../../icons/sport2000-icons/TeamsportExperts_CMYK.svg'
import { ReactComponent as AbsoluteTeamSportLogo } from '../../../../../../../../icons/sport2000-icons/logos/absoluteteamsport.svg'
import { ReactComponent as AbsoluteRunLogo } from '../../../../../../../../icons/sport2000-icons/logos/absoluterun.svg'
import { ReactComponent as AbsoluteSportLogo } from '../../../../../../../../icons/sport2000-icons/logos/absolutesports.svg'
import SearchFilter from '../../../SearchFilter'

type Props = {
    items: Array<Hit<{ count: number; isRefined: boolean; label: string; value: string[] }>>
    refine: (value: string[]) => any;
    attribute: any
    isLogo?: boolean
    algoliaIndexName: string
    showSearchBar: boolean
}

export const List: React.FC<Props> = ({
    items, refine, attribute, isLogo, algoliaIndexName, showSearchBar,
}: Props) => {
    const LIMIT_DATA_PER_ALGOLIA_TRACKING = 10
    const [filterList, setFilterList] = useState(items)

    useEffect(() => {
        setFilterList(items)
    }, [items])

    const handleLabel = (item) => {
        if (!isLogo) {
            return item.label
        }

        const LOGOS = {
            absolute_run: <AbsoluteRunLogo width={175} />,
            absolute_teamsport: <AbsoluteTeamSportLogo width={250} />,
            running_expert: <RunningExpertLogo height={24} width={94} />,
            teamsport_expert: <TeamsportExpertLogo height={24} width={111} />,
            absolute_sport: <AbsoluteSportLogo width={230} />,
        }

        return (
            LOGOS[item.label] || null
        )
    }

    return (
        <ul className={'filter-algolia--panel-select-list'}>
            {showSearchBar && (
                <SearchFilter items={items} setFilterList={setFilterList} />
            )}

            {!filterList.length ? (
                <div className={'px-4'}>
                    <p className={'font-bold mt-4 mb-2'}>
                        <FormattedMessage id={'filters.noResultHeadline'} />
                    </p>
                    <p className={'text-sm'}>
                        <FormattedMessage id={'filters.noResultDesc'} />
                    </p>
                </div>
            ) : (
                <>
                    {filterList?.map((item, index) => (
                        <li
                            key={item.label}
                            {...(attribute === 'brand' && index < LIMIT_DATA_PER_ALGOLIA_TRACKING ? {
                                'data-insights-filter': `${attribute}:${item.value}`,
                                'data-insights-index': algoliaIndexName,
                            } : {})}
                        >
                            <div className={'form-check'}>
                                <input
                                    className={'form-check-input'}
                                    type={'checkbox'}
                                    checked={item.isRefined}
                                    onChange={() => {
                                        refine(item.value)
                                    }}
                                    id={item.label}
                                />
                                <label
                                    className={classnames('form-check-label ais-RefinementList-labelText', {
                                        'is--logo': isLogo,
                                    })}
                                    htmlFor={item.label}
                                >
                                    {handleLabel(item)}
                                    <span>
                                        {item.count}
                                    </span>
                                </label>
                            </div>
                        </li>
                    ))}
                </>
            )}
        </ul>
    )
}
