import React from 'react'
import { connectStateResults } from 'react-instantsearch-dom'
import { FormattedMessage } from 'react-intl'

type Props = {
    searchResults: any,
}

const CountProduct: React.FC<Props> = ({
    searchResults,
}) => {
    if (!searchResults || !searchResults.nbHits) {
        return null
    }

    return (
        <>
            <div className={'sort-algolia--count'}>
                {searchResults.nbHits}
                {' '}
                <FormattedMessage id={'product.product'} />
            </div>
        </>
    )
}

export default connectStateResults(CountProduct)
