import React from 'react'
import Select from './Select'

export type Props = {
    attribute: string
    limit: number
    index?: number
    isActiveFilter?: boolean
    facets: object
    refine: (value: string[]) => any
    currentRefinement: object | null
}

const FilterTypes: React.FC<Props> = (props: Props) => {
    const { attribute, isActiveFilter } = props
    const LOGO = 'specialization_logos'

    switch (attribute) {
        case LOGO:
            return <Select {...props} isActiveFilter={isActiveFilter} isLogo />
        default:
            return <Select {...props} />
    }
}

export default FilterTypes
