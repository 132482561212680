import React, { useState } from 'react'
import classnames from 'classnames'
import { connectSortBy, connectStateResults } from 'react-instantsearch-dom'
import IconButton from '../../../atoms/button/IconButton'
import { ReactComponent as ArrowDown } from '../../../../../icons/sport2000-icons/arrow-down.svg'

type Props = {
    searchResults: any,
    isDisplaySorting: boolean,
    items: any
    refine: any
}

const Sort: React.FC<Props> = ({
    searchResults, isDisplaySorting, items, refine,
}) => {
    if (!searchResults || !searchResults.nbHits) {
        return null
    }

    const [isOptionsOpen, setIsOptionsOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState('Sortieren nach')

    const toggleOptions = () => {
        setIsOptionsOpen(!isOptionsOpen)
    }

    return (
        <>
            {isDisplaySorting && items.length > 0 && (
                <div className={'sort-by-algolia'}>
                    <IconButton
                        type={'button'}
                        ariaLabel={'sort-button'}
                        aria-haspopup={'listbox'}
                        aria-expanded={isOptionsOpen}
                        className={classnames('sort-by-algolia-button', {
                            expanded: isOptionsOpen,
                        })}
                        onClick={toggleOptions}
                        icon={<ArrowDown className={'sort-by-algolia-button-icon'} width={15} height={15} />}
                    >
                        {selectedOption}
                    </IconButton>
                    {isOptionsOpen && (
                        <ul className={classnames('sort-by-algolia-wrap', {
                            'show-sort': isOptionsOpen,
                        })}
                        >
                            {items.map((item) => (
                                <li
                                    id={item.value}
                                    role={'option'}
                                    aria-selected={selectedOption === item.isRefined}
                                    key={item.value}
                                    className={classnames('option', {
                                        'option-selected': item.isRefined,
                                    })}
                                    onClick={(event) => {
                                        event.preventDefault()
                                        setIsOptionsOpen(false)
                                        refine(item.value)
                                        setSelectedOption(item.label)
                                    }}
                                >
                                    {item.label}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            )}
        </>
    )
}

export default connectSortBy(connectStateResults(Sort))
