import React from 'react'

interface SeoBlockProps {
  seoH2?: string;
  seoText?: string;
}

const SEOBlock: React.FC<SeoBlockProps> = ({ seoH2, seoText }) => {
  if (!seoH2 && !seoText) return null
  return (
    <div className="flex flex-col gap-4">
      <h2>{seoH2}</h2>
      <div dangerouslySetInnerHTML={{ __html: seoText }} />
    </div>
  )
}

export default SEOBlock
