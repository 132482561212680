import React, { useEffect, useState } from 'react'
import { connectRange } from 'react-instantsearch-core'
import { useSelector } from 'react-redux'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import classnames from 'classnames'
import PanelButton from '../../PanelButton'
import ValuesPanel from '../../ValuesPanel'
import { Context } from '../../../../../../types/frontastic'
import useComponentVisible from '../../../../../../utils/hooks/UseComponentVisible'
import PanelContent from './PanelContent'
import { ProductListState } from '../../../../../../store/reducers/ProductListReducer'

export type Props = {
    context: Context
    attribute: string
    min: number
    max: number
    count: {
        value: string
        count: number
    }[]
    currentRefinement: {
        min: number
        max: number
    }
    canRefine: boolean
    refine: (refinement: { min: number, max: number }) => void
    setRenderedRefinementList?: React.Dispatch<React.SetStateAction<JSX.Element | undefined>>
    isMobile?: boolean
    currentSecondLevelFilter?: string
    index: number
}

const Price: React.FC<Props> = ({
    min,
    max,
    currentRefinement,
    refine,
    attribute,
    isMobile,
    setRenderedRefinementList,
    currentSecondLevelFilter,
    index,
}: Props) => {
    const activeFilter = useSelector((state) => (state['product-list-algolia'] as ProductListState).openFilter)
    const hasShowMore = useSelector((state) => state['product-list-algolia'].showMore)
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
    const isOpen = activeFilter === attribute

    const [stateMin, setStateMin] = useState<number>(min / 100)
    const [stateMax, setStateMax] = useState<number>(max / 100)
    const [debounce, setDebounce] = useState<NodeJS.Timeout>()
    const isDesktop = useDeviceType() === 'desktop'

    useEffect(() => {
        setStateMin(currentRefinement.min / 100)
        setStateMax(currentRefinement.max / 100)
    }, [currentRefinement.min, currentRefinement.max])

    useEffect(() => () => clearTimeout(debounce), [])

    if (!min || !max) {
        return null
    }

    const onFilter = (tmpMin, tmpMax) => {
        refine({
            min: tmpMin * 100 || stateMin * 100,
            max: tmpMax * 100 || stateMax * 100,
        })
    }

    if (isMobile) {
        if (currentSecondLevelFilter === attribute && setRenderedRefinementList) {
            setRenderedRefinementList(
                <PanelContent
                    min={min}
                    max={max}
                    stateMin={stateMin}
                    stateMax={stateMax}
                    setStateMin={setStateMin}
                    setStateMax={setStateMax}
                    onFilter={onFilter}
                />,
            )
        }

        return null
    }

    return (
        <div
            ref={ref}
            className={classnames('filter-algolia--panel-wrapper', {
                hidden: hasShowMore && index >= 5 && isDesktop,
            })}
        >
            <PanelButton
                attribute={attribute}
                isOpen={isOpen}
                isComponentVisible={isComponentVisible}
                setIsComponentVisible={setIsComponentVisible}
            />
            {isOpen && isComponentVisible && (
                <ValuesPanel>
                    <PanelContent
                        min={min / 100}
                        max={max / 100}
                        stateMin={stateMin}
                        stateMax={stateMax}
                        setStateMin={setStateMin}
                        setStateMax={setStateMax}
                        onFilter={onFilter}
                    />
                </ValuesPanel>
            )}
        </div>
    )
}

export default connectRange(Price)
