import fetch from '@frontastic/bridge/js/fetch'

class Blog {
    async getBlogTitle(categoryKey) {
        const res = await fetch(`/api/graphcms/productlist/tile/${categoryKey}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return res.json()
    }
}

export default new Blog()
