import React from 'react'
import Proptypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { ReactComponent as CloseIcon } from '../../../../../../icons/sport2000-icons/close.svg'

const CurrentRefinementList = ({
    refine, items, intl, isInSpecialPage,
}) => {
    if (!items || !items.length) {
        return null
    }

    return (
        <div className={'store-finder--current-refinment'}>
            {items.length > 1 && !isInSpecialPage && (
                <div className={'store-finder--clear-refinement is-reset-all'}>
                    <FormattedMessage id={'filters.removeAllCurrent'} />

                    <button
                        type={'button'}
                        aria-label={intl.formatMessage({ id: 'filters.clearAll' })}
                        onClick={() => refine()}
                    >
                        <CloseIcon
                            width={10}
                            height={10}
                        />
                    </button>
                </div>
            )}

            {items.map((item) => (
                <div className={'store-finder--clear-refinement'} key={item.label}>
                    {item.label}

                    {!isInSpecialPage && (
                        <button
                            type={'button'}
                            aria-label={item.label}
                            onClick={() => refine(item)}
                        >
                            <CloseIcon
                                width={10}
                                height={10}
                            />
                        </button>
                    )}
                </div>
            ))}
        </div>
    )
}

CurrentRefinementList.propTypes = {
    refine: Proptypes.any,
    items: Proptypes.any,
    intl: Proptypes.any,
    isInSpecialPage: Proptypes.any,
}

export default injectIntl(CurrentRefinementList)
