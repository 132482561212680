import React from 'react'
import Proptypes from 'prop-types'
import FilterTypes from './FilterTypes'

const Filter = ({
    isActiveFilter, facets, attributeToRender, refine, currentRefinement,
}) => {
    if (!attributeToRender?.facets?.order || !attributeToRender?.facets?.order.length) {
        return null
    }

    const facetDisplay = attributeToRender?.values

    return (
        attributeToRender?.facets?.order.map((attribute) => (
            <FilterTypes
                attribute={attribute}
                key={attribute}
                isActiveFilter={isActiveFilter}
                facets={facets}
                refine={refine}
                facetDisplay={facetDisplay}
                currentRefinement={currentRefinement}
            />
        ))
    )
}

Filter.propTypes = {
    isActiveFilter: Proptypes.any,
    facets: Proptypes.any,
    attributeToRender: Proptypes.any,
    currentRefinement: Proptypes.any,
}

export default Filter
