export class StringHelpers {
  /**
   * isNumeric tests a string and returns true when it's a decimal value
   */
  static isNumeric = (val: string) => /^\d+$/.test(val);

  /**
   * capitaliseFirstLetter capitalises only the very first character of a string, leaving the
   * rest unedited
   */
  static capitaliseFirstLetter = (val: string) => val.charAt(0).toUpperCase() + val.slice(1);

  static parseRobotsMeta(metaString: string | null): string {
    if (!metaString) return '';

    const parts = metaString.split('_');
    const validDirectives = ['index', 'noindex', 'follow', 'nofollow'];

    const directives = parts.map(part => {
      if (validDirectives.includes(part.toLowerCase())) {
        return part.toLowerCase();
      }
      return null;
    }).filter(Boolean);

    return directives.join(',');
  }
}
