import React from 'react'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import { Context } from '../../../types/frontastic'
import StoreFinder from '../../../patterns/organisms/Algolia/StoreFinder'

type Props = {
    context: Context
    data: any
}

const StoreFinderTastic: React.FC<Props> = ({ context, data }) => {
    const googleMapKey = context.project.configuration.googleMap.mapKey
    const isDesktop = useDeviceType() === 'desktop'
    const isTablet = useDeviceType() === 'tablet'

    let heightHeader = 50
    let offsetY = 80

    if (isTablet) {
        heightHeader = 180
        offsetY = 100
    }

    if (isDesktop) {
        heightHeader = 168
        offsetY = 150
    }

    const baseGoogleScript = 'https://maps.googleapis.com/maps/api/js'
    const googleScript = () => {
        const locale = context.locale || 'de_DE'
        const localeParts = locale.split('_')
        const language = localeParts[0] || 'de'
        const country = localeParts[1] || 'DE'

        return `${baseGoogleScript}?key=${googleMapKey}&libraries=geometry,places&language=${language}&region=${country}`
    }

    return (
        <>
            <div className={'custom-cmp'} style={{ minHeight: `calc(100vh - ${heightHeader}px)`, width: '100%' }}>
                <script
                    async
                    className={'cmplazyload'}
                    referrerPolicy={'no-referrer-when-downgrade'}
                    data-cmp-src={googleScript()}
                    data-cmp-vendor={'s1104'}
                    data-cmp-preview={'500x300'}
                    data-cmp-purpose={'c1'}
                    data-cmp-preview-headline={'Um Karten von Google Maps anzeigen zu können, stimmen Sie der Verwendung auch optionaler Cookies zu.'}
                    data-cmp-preview-btn={'Akzeptieren'}
                    data-cmp-preview-text={'Dieser Inhalt wird von Google Maps bereit gestellt. Wenn Sie den Inhalt aktivieren, werden ggf. personenbezogene Daten verarbeitet und Cookies gesetzt.'}
                    data-cmp-preview-checkbox={'Google Maps immer aktivieren.'}
                    data-cmp-preview-image={'https://res.cloudinary.com/dlwdq84ig/image/upload/v1669045270/tdwhusl09cfwsksnkeat.jpg'}
                    data-cmp-preview-bgcolor={'#FFFFFF'}
                    data-cmp-preview-mode={'0'}
                />
                <StoreFinder
                    // @ts-ignore
                    context={context}
                    data={data}
                    // @ts-ignore
                    heightHeader={heightHeader}
                    // @ts-ignore
                    offsetY={offsetY}
                />
            </div>
        </>
    )
}

export default tastify({
    translate: true,
    connect: {
        node: true, tastic: true, route: true, context: true,
    },
})(StoreFinderTastic)
