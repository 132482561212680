import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import _ from 'lodash'
import Proptypes from 'prop-types'
import { SwiperSlide } from 'swiper/react'
import { injectIntl } from 'react-intl'
import Slider from '../../../../atoms/slider'
import StoreItem from './StoreItem'
import StoreService from '../../../../../services/algolia/StoreService'
import useStoreCookie from '../../../../../services/hook/useStoreCookie'

const StoreSlider = ({
    data,
    stores,
    selectedLocation,
    selectedStore,
    storeSlider,
    isShowSlider,
    isActiveSearch,
    onSelect,
    stickyLocation,
    context,
}) => {
    const isDesktop = useDeviceType() === 'desktop'
    const [activeStore, setActiveStore] = useState(null)
    const {
        updateCookieStore, cookieStore,
    } = useStoreCookie(context)
    const objectId = cookieStore?.id
    const hitRef = useRef({
        hits: [],
    })

    const scrollTo = (el) => {
        if (!el) {
            return
        }

        const elLeft = el.offsetLeft + el.offsetWidth
        const elParentLeft = el.parentNode.offsetLeft + el.parentNode.offsetWidth

        if (elLeft >= elParentLeft + el.parentNode.scrollLeft) {
            el.parentNode.scrollLeft = elLeft - elParentLeft
        } else if (elLeft <= el.parentNode.offsetLeft + el.parentNode.scrollLeft) {
            el.parentNode.scrollLeft = el.offsetLeft - el.parentNode.offsetLeft
        }
    }

    useEffect(() => {
        if (selectedStore && isActiveSearch) {
            let storeActiveIndex = _.findIndex(hitRef.current.hits, (hit) => hit.objectID === selectedStore.objectId)
            storeActiveIndex = storeActiveIndex !== -1 ? storeActiveIndex : 0

            if (isDesktop) {
                setActiveStore({
                    id: selectedStore.objectId,
                    index: storeActiveIndex,
                })
            } else {
                const elStore = document.querySelector(`.store-slider-item:nth-child(${storeActiveIndex + 1})`)
                scrollTo(elStore)
            }
        }
    }, [selectedStore, storeSlider, stores])

    useEffect(() => {
        if (!isActiveSearch && stickyLocation) {
            setActiveStore({
                id: stickyLocation.objectId,
                index: 0,
            })
        }
    }, [stickyLocation])

    const handleSelectStore = (hit) => {
        updateCookieStore(hit)
    }

    const renderSlider = () => {
        let distance = 0
        let tmpStores = isActiveSearch ? stores : storeSlider
        
        tmpStores.forEach((hit) => {
            distance = StoreService.calculateDistance(hit._geoloc, !isActiveSearch && stickyLocation ? stickyLocation
                : (selectedLocation || { lat: data.lat, lng: data.lng }))
            hit.distance = distance
        })

        tmpStores = _.sortBy(tmpStores, ['distance'])
        hitRef.current.hits = tmpStores

        if (isDesktop) {
            return (
                <Slider
                    activeSlide={activeStore}
                    spaceBetween={20}
                    slidesPerView={4}
                    slidesPerGroup={4}
                    loop={false}
                    breakpoints={{
                        1024: {
                            slidesPerView: 2.1,
                            slidesPerGroup: 2,
                        },
                        1280: {
                            slidesPerView: 3.1,
                            slidesPerGroup: 3,
                        },
                        1500: {
                            slidesPerView: 4.1,
                            slidesPerGroup: 4,
                        },
                    }}
                >
                    {tmpStores.map((store, index) => (
                        <SwiperSlide key={store.account_id + index}>
                            <StoreItem
                                hit={store}
                                selectedStore={selectedStore}
                                isFavouriteStore={objectId === store.objectID}
                                onClick={(hit) => handleSelectStore({
                                    ...hit,
                                    isFromSlider: true,
                                })}
                                onSelect={(hit) => onSelect(hit)}
                            />
                        </SwiperSlide>
                    ))}
                </Slider>
            )
        }

        return (
            tmpStores.map((store) => (
                <StoreItem
                    key={store.account_id}
                    hit={store}
                    selectedStore={selectedStore}
                    isFavouriteStore={objectId === store.objectID}
                    onClick={(hit) => handleSelectStore({
                        ...hit,
                        isFromSlider: true,
                    })}
                    isActiveSearch={isActiveSearch}
                    onSelect={(hit) => onSelect(hit)}
                />
            ))
        )
    }

    return (
        <div
            className={'store-finder--slider'}
        >
            <div className={classnames('store-finder--slider-wrapper', {
                active: isShowSlider,
            })}
            >
                {renderSlider()}
            </div>
        </div>
    )
}

StoreSlider.propTypes = {
    data: Proptypes.any,
    selectedLocation: Proptypes.any,
    selectedStore: Proptypes.any,
    storeSlider: Proptypes.any,
    isShowSlider: Proptypes.any,
    isActiveSearch: Proptypes.any,
    onSelect: Proptypes.any,
    stickyLocation: Proptypes.any,
    stores: Proptypes.any,
    context: Proptypes.any,
}
export default injectIntl(StoreSlider)
