import React from 'react'
import classnames from 'classnames'
import { Hit } from 'react-instantsearch-core'
import Sport2000Logo from '../../../../../../../atoms/sport2000Logo'
import {
    ReactComponent as AbsoluteSportLogo,
} from '../../../../../../../../../icons/sport2000-icons/logos/absolutesports.svg'
import {
    ReactComponent as OutdoorProfiLogo,
} from '../../../../../../../../../icons/sport2000-icons/logos/outdoor_profi_CMYK.svg'

type Props = {
    items: Array<Hit<{ count: number; isRefined: boolean; label: string; value: string[] }>>
    attribute: any
    refine: (value: object) => any
    isLogo?: boolean
    currentRefinement: any[]
}

export const List: React.FC<Props> = ({
    items, attribute, isLogo, refine, currentRefinement,
}: Props) => {
    const handleLabel = (item) => {
        if (!isLogo) {
            return item.label
        }

        const LOGOS = {
            absolute_run: <Sport2000Logo name={'AbsoluteRun_1line'} alt={'Logo Absolute Run'} width={'160px'} />,
            absolute_teamsport: <Sport2000Logo name={'AbsoluteTeamsport_1line'} alt={'Logo Absolute TeamSport'} width={'230px'} />,
            running_expert: <Sport2000Logo name={'RunningExperts_1line'} alt={'Logo Running Expert'} height={'32px'} />,
            teamsport_expert: <Sport2000Logo name={'TeamsportExperts_1line'} alt={'Logo TeamSport Expert'} height={'32px'} />,
            absolute_sport: <AbsoluteSportLogo width={230} />,
            outdoor_expert: <Sport2000Logo name={'OutdoorExperts_1line'} alt={'Logo Outdoor Expert'} height={'32px'} />,
            ski_expert: <Sport2000Logo name={'SkiExperts_1line'} alt={'Logo Ski Expert'} height={'32px'} />,
            outdoorprofi: <OutdoorProfiLogo width={90} height={24} />,
        }

        return (
            LOGOS[item.label] || null
        )
    }

    return (
        <ul className={'filter-algolia--panel-select-list'}>
            {items.map((item) => {
                const isActive = currentRefinement.findIndex((crm) => crm.value === item.value) !== -1

                return (
                    <li key={item.label} data-insights-filter={`${attribute}:${item.value}`}>
                        <div className={'form-check'}>
                            <input
                                className={'form-check-input'}
                                type={'checkbox'}
                                checked={isActive}
                                onChange={() => {
                                    refine(item)
                                }}
                                id={item.label}
                            />
                            <label
                                className={classnames('form-check-label ais-RefinementList-labelText', {
                                    'is--logo': isLogo,
                                })}
                                htmlFor={item.label}
                            >
                                {handleLabel(item)}
                                <span>
                                    {item.count}
                                </span>
                            </label>
                        </div>
                    </li>
                )
            })}
        </ul>
    )
}
