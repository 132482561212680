import React from 'react'
import { Hit } from 'react-instantsearch-core'
import { WrappedComponentProps, injectIntl, IntlShape } from 'react-intl'

type Props = {
    item: Hit<{ count: number; isRefined: boolean; label: string; value: string[] }>
    intl: IntlShape
    refine: (value: string[]) => any;
    attribute: any;
} & WrappedComponentProps

const ColorCheck: React.FC<Props> = ({
    item, intl, refine, attribute,
}: Props) => (
    <li key={item.label} data-insights-filter={`${attribute}:${item.value}`}>
        <div className={'form-check form-check-color'}>
            <input
                className={'form-check-input'}
                type={'checkbox'}
                checked={item.isRefined}
                onChange={() => {
                    refine(item.value)
                }}
                id={item.label}
            />
            <label
                className={'form-check-color-label ais-RefinementList-labelText'}
                htmlFor={item.label}
            >
                {item.label}
                <span>
                    (
                    {item.count}
                    )
                </span>
            </label>
        </div>
    </li>
)

export default injectIntl(ColorCheck)
