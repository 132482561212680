import React, { useEffect, useState } from 'react'
import { connectRefinementList } from 'react-instantsearch-dom'
import { RefinementListProvided } from 'react-instantsearch-core'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import {
    FormattedMessage,
} from 'react-intl'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import useComponentVisible from '../../../../../../utils/hooks/UseComponentVisible'
import PanelButton from '../../PanelButton'
import ValuesPanel from '../../ValuesPanel'
import ColorCheck from './ColorCheck'
import SearchFilter from '../../SearchFilter'
import AttributeTranslationService from '../../../../../../services/algolia/AttributeTranslationService'

export type Props = {
    attribute: string
    index: number
} & RefinementListProvided

const Color: React.FC<Props> = ({
    items,
    refine,
    attribute,
    currentRefinement,
    index,
}: Props) => {
    const activeFilter = useSelector((state) => state['product-list-algolia'].openFilter)
    const hasShowMore = useSelector((state) => state['product-list-algolia'].showMore)
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
    const isOpen = activeFilter === attribute
    const showSearchBar = AttributeTranslationService.showSearchBarFilter(attribute)
    const isDesktop = useDeviceType() === 'desktop'

    if (items.length === 0) {
        return null
    }

    const [filterList, setFilterList] = useState(items)

    useEffect(() => {
        setFilterList(items)
    }, [items])

    return (
        <div
            ref={ref}
            className={classnames('filter-algolia--panel-wrapper', {
                hidden: hasShowMore && index >= 5 && isDesktop,
            })}
        >
            <PanelButton
                attribute={attribute}
                isOpen={isOpen}
                isComponentVisible={isComponentVisible}
                setIsComponentVisible={setIsComponentVisible}
            />
            {isOpen && isComponentVisible && (
                <ValuesPanel>
                    <ul className={'filter-algolia--panel-select-list'}>
                        {showSearchBar && (
                            <SearchFilter items={items} setFilterList={setFilterList} />
                        )}
                        {!filterList.length ? (
                            <div className={'px-4'}>
                                <p className={'font-bold mt-4 mb-2'}>
                                    <FormattedMessage id={'filters.noResultHeadline'} />
                                </p>
                                <p className={'text-sm'}>
                                    <FormattedMessage id={'filters.noResultDesc'} />
                                </p>
                            </div>
                        ) : (
                            <>
                                {filterList?.map((item) => (
                                    <ColorCheck
                                        key={item.label}
                                        item={item}
                                        refine={refine}
                                        attribute={attribute}
                                    />
                                ))}
                            </>
                        )}
                    </ul>
                </ValuesPanel>
            )}
        </div>
    )
}

export default connectRefinementList(Color)
