import React, { PropsWithChildren } from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'

type Props = {
    className?: string
} & WrappedComponentProps

const ValuesPanel: React.FC<PropsWithChildren<Props>> = ({
    children, className,
}) => (
    <div className={`filter-algolia--panel-values ${className || ''}`}>
        {children}
    </div>
)

export default injectIntl(ValuesPanel)
