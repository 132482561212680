import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { NarrowLeftIcon } from '../../../atoms/icon';
import {
  findSelectedCategoryInStorePage,
  getCategoriesToShowOnSlideInCategoryPage,
  getCategoriesToShowOnSlideInSaleCategoryPage,
  getCategoriesToShowOnSlideInStorePage,
  getProductCount,
} from '../SidebarMenu/categoryHelper';
import Link from 'next/link';

type Props = {
  currentCategory: any;
  node: any;
  categoryTree?: any;
  facetCategory?: any;
  displaySidebarByResult: boolean;
};

const FIRST_CATEGORY_LEVEL_VISIBLE = 1;
const OFFSET_FROM_FIRST_BADGE_TO_LEFT = 52;

const SlideMenu: React.FC<Props> = ({
  currentCategory,
  node,
  categoryTree,
  facetCategory,
  displaySidebarByResult,
}) => {
  const { query } = useRouter();
  const categorySidebarQuery = query.categorySidebar;
  const pathName = query?.path || '';

  const slideMenuRef = useRef<HTMLDivElement>(null);

  // Determine categories to display based on node type
  const { categoriesToShowOnSlide, selectedCategory, parentOfSelectedCategory } = (() => {
    if (node.nodeType === 'store' || displaySidebarByResult) {
      const { selectedCategory, parentOfSelectedCategory } = findSelectedCategoryInStorePage(
        categoryTree,
        `${categorySidebarQuery}`
      );
      return {
        categoriesToShowOnSlide: getCategoriesToShowOnSlideInStorePage(
          selectedCategory,
          parentOfSelectedCategory,
          categoryTree
        ),
        selectedCategory,
        parentOfSelectedCategory,
      };
    } else if (node.nodeType === 'category') {
      return {
        categoriesToShowOnSlide: getCategoriesToShowOnSlideInCategoryPage(currentCategory, categoryTree),
        selectedCategory: currentCategory,
        parentOfSelectedCategory: currentCategory?.projectSpecificData?.parent,
      };
    } else if (node.nodeType === 'sale-category') {
      return {
        categoriesToShowOnSlide: getCategoriesToShowOnSlideInSaleCategoryPage(currentCategory, categoryTree),
        selectedCategory: currentCategory,
        parentOfSelectedCategory: currentCategory?.projectSpecificData?.parent,
      };
    }
    return { categoriesToShowOnSlide: categoryTree, selectedCategory: null, parentOfSelectedCategory: null };
  })();

  // Helper functions
  const generatePathInStorePage = (category: any) => {
    return category?.id ? `${pathName}?categorySidebar=${category.id}` : pathName;
  };

  const getCategoryUrl = (category: any) => {
    if (node.nodeType === 'store' || displaySidebarByResult) {
      return isCategorySelected(category.id)
        ? generatePathInStorePage(parentOfSelectedCategory)
        : getProductCount(category, facetCategory) > 0
          ? generatePathInStorePage(category)
          : '#';
    }
    return isCategorySelected(category.id)
      ? parentOfSelectedCategory?.url || '#'
      : getProductCount(category, facetCategory) > 0
        ? category?.url
        : '#';
  };

  const isCategorySelected = (categoryId: string) => {
    return node.nodeType === 'store' || displaySidebarByResult
      ? categoryId === selectedCategory?.id
      : categoryId === selectedCategory?.categoryId;
  };

  const isShowBackButton = () => {
    if (node.nodeType === 'store' || displaySidebarByResult) {
      return Boolean(selectedCategory?.level >= FIRST_CATEGORY_LEVEL_VISIBLE);
    }
    if (node.nodeType === 'sale-category') {
      return Boolean(currentCategory?.depth >= FIRST_CATEGORY_LEVEL_VISIBLE && parentOfSelectedCategory);
    }
    return Boolean(currentCategory?.depth > FIRST_CATEGORY_LEVEL_VISIBLE && parentOfSelectedCategory);
  };

  const hrefOfBackButton = () => {
    return node.nodeType === 'store' || displaySidebarByResult
      ? generatePathInStorePage(parentOfSelectedCategory)
      : parentOfSelectedCategory?.url || '#';
  };

  // Scroll to the selected category
  useEffect(() => {
    const selectedBadgeElement = document.getElementById(`badge-${currentCategory.categoryId}`);
    if (selectedBadgeElement) {
      const calculatedOffset =
        selectedBadgeElement.offsetLeft > OFFSET_FROM_FIRST_BADGE_TO_LEFT
          ? selectedBadgeElement.offsetLeft - OFFSET_FROM_FIRST_BADGE_TO_LEFT
          : 0;
      slideMenuRef.current?.scrollTo({ left: calculatedOffset, behavior: 'smooth' });
    }
  }, [currentCategory.categoryId]);

  // Render
  return (
    <div ref={slideMenuRef} className="slider-wrapper laptop:hidden">
      {isShowBackButton() && (
        <Link href={hrefOfBackButton()} prefetch={true}>
          <div className="mr-1 flex h-8 w-8 items-center justify-center rounded-full border border-black">
            <NarrowLeftIcon width={16} height={16} />
          </div>
        </Link>
      )}

      {categoriesToShowOnSlide?.map((category) => (
        <Link
          key={category.id}
          id={`badge-${category.id}`}
          className="no-underline"
          href={getCategoryUrl(category)}
          prefetch={true}
        >
          <div
            className={classNames(
              'border border-black inline-block mb-3 whitespace-no-wrap font-medium px-4 py-2 m-0 flex items-center justify-center rounded-2xl text-sm leading-none h-8',
              {
                'text-white bg-black': isCategorySelected(category.id),
                'text-black': !isCategorySelected(category.id),
              }
            )}
          >
            {category?.name}
          </div>
        </Link>
      ))}
    </div>
  );
};

export default SlideMenu;
