import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import RangeSlider from '../../../../../../atoms/rangeSlider'

type Props = {
    onFilter: (min?: any, max?: any) => void
    min: number
    max: number
    stateMin: number
    stateMax: number
    setStateMin: React.Dispatch<React.SetStateAction<number>>
    setStateMax: React.Dispatch<React.SetStateAction<number>>
}

const PanelContent: React.FC<Props> = ({
    max,
    min,
    onFilter,
    stateMax,
    stateMin,
    setStateMax,
    setStateMin,
}: Props) => {
    const [tmpMin, setTmpMin] = useState(stateMin)
    const [tmpMax, setTmpMax] = useState(stateMax)

    useEffect(() => {
        setTmpMin(stateMin)
        setTmpMax(stateMax)
    }, [stateMin, stateMax])

    const handlePriceRange = (val: Number[]) => {
        setStateMin(+val[0])
        setStateMax(+val[1])
        setTmpMin(+val[0])
        setTmpMax(+val[1])
    }

    const handlePriceInput = () => {
        const minPrice = parseInt(`${tmpMin}`, 10) < min ? min : parseInt(`${tmpMin}`, 10)
        const maxPrice = parseInt(`${tmpMax}`, 10) > max ? max : parseInt(`${tmpMax}`, 10)
        setTmpMin(Number.isNaN(minPrice) ? min : minPrice)
        setTmpMax(Number.isNaN(maxPrice) ? max : maxPrice)
        onFilter(minPrice, maxPrice)
    }

    const formatPrice = (value) => `${value}`.replace('.', ',')

    return (
        <div className={'filter-algolia--price'}>
            <div className={'product-filter-price--input'}>
                <div className={'product-filter-price--input-wrapper'}>
                    <input
                        name={'min'}
                        type={'input'}
                        value={formatPrice(tmpMin)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                onFilter(tmpMin, tmpMax)
                            }
                        }}
                        onChange={(e) => setTmpMin(+e.target.value)}
                        onBlur={() => handlePriceInput()}
                    />
                    <FormattedMessage
                        id={'filters.unit'}
                    />
                </div>
                <span />
                <div className={'product-filter-price--input-wrapper'}>
                    <input
                        name={'max'}
                        type={'input'}
                        value={formatPrice(tmpMax)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                onFilter(tmpMin, tmpMax)
                            }
                        }}
                        onChange={(e) => setTmpMax(+e.target.value)}
                        onBlur={() => handlePriceInput()}
                    />
                    <FormattedMessage
                        id={'filters.unit'}
                    />
                </div>
            </div>
            <div className={'product-filter-price--range'}>
                <RangeSlider
                    value={[stateMin, stateMax]}
                    min={min}
                    max={max}
                    onChange={(v) => handlePriceRange(v)}
                    onAfterChange={onFilter}
                />
            </div>
        </div>
    )
}

export default PanelContent
