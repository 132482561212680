import { Property } from 'csstype'
import { IntlShape } from 'react-intl'
import { FilterTypeEnum } from '../../patterns/organisms/Algolia/Filter/FilterTypeEnum'
import { Context } from '../../types/frontastic'

export type TranslatedFilterColor = {
    filterColor: string,
    hex: Property.BackgroundColor | null,
    translatedLabel: string
}

export default class FilterService {
    protected static readonly mapping: { [key: string]: FilterTypeEnum } = {
        price: FilterTypeEnum.PRICE,
        color: FilterTypeEnum.COLOR,
        specialization_logos: FilterTypeEnum.LOGO,
    }

    protected static readonly colorHexMapping = {
        blue: '#213CE0',
        other: null,
        black: '#000',
        grey: '#999',
        green: '#27B32A',
        brown: '#8C6D41',
        beige: '#C5B386',
        white: '#fff',
        orange: '#E19427',
        red: '#E32626',
        yellow: '#E0E21A',
        violet: '#841C9E',
    }

    protected static readonly filterableFlags: string[] = [
        'sale',
        'new',
        'nachhaltig',
    ]

    public static isFilterAvailable(attribute: string, context: Context): boolean {
        const filterType = this.mapFilterAttribute(attribute)
        let currency: string | undefined

        if (filterType === FilterTypeEnum.PRICE) {
            currency = attribute.split('.').at(-1)
        }

        switch (filterType) {
            case FilterTypeEnum.PRICE:
                return !(!currency || currency.toUpperCase() !== context.currency.toUpperCase())
            case FilterTypeEnum.COLOR:
            case FilterTypeEnum.DEFAULT:
            default:
                return true
        }
    }

    public static mapAndTranslateFilterColor(
        filterColor: string, intl: IntlShape,
    ): TranslatedFilterColor {
        const hex = this.colorHexMapping[filterColor] || null

        return {
            filterColor,
            hex,
            translatedLabel: intl.formatMessage({ id: `productList.color.${filterColor}` }),
        }
    }

    public static isFlagAvailable(flag: string): boolean {
        return this.filterableFlags.includes(flag)
    }

    public static mapFilterAttribute(attribute: string): FilterTypeEnum {
        if (this.mapping[attribute]) {
            return this.mapping[attribute]
        }

        return FilterTypeEnum.DEFAULT
    }
}
