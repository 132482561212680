import {
    useCallback, useEffect, useRef, useState,
} from 'react'
import debounceFn from 'lodash.debounce'

export default function usePlacesAutocompleteService({
    debounce = 300,
    options = {},
    sessionToken,
}) {
    const [placePredictions, setPlacePredictions] = useState([])
    const [isPlacePredsLoading, setIsPlacePredsLoading] = useState(false)
    const [placeInputValue, setPlaceInputValue] = useState(null)
    const [isQueryPredsLoading, setIsQueryPredsLoading] = useState(false)
    const [queryInputValue, setQueryInputValue] = useState(false)
    const [queryPredictions, setQueryPredictions] = useState([])
    const placesAutocompleteService = useRef(null)
    const placesService = useRef(null)
    const autocompleteSession = useRef(null)

    const debouncedPlacePredictions = useCallback(
        debounceFn((optionsArg) => {
            if (placesAutocompleteService.current && optionsArg.input) {
                placesAutocompleteService.current.getPlacePredictions(
                    {
                        ...(sessionToken && autocompleteSession.current
                            ? { sessionToken: autocompleteSession.current }
                            : {}),
                        ...options,
                        ...optionsArg,
                    },
                    (r) => {
                        setIsPlacePredsLoading(false)
                        setPlacePredictions(r || [])
                    },
                )
            }
        }, debounce),
        [debounce],
    )

    const debouncedQueryPredictions = useCallback(
        debounceFn((optionsArg) => {
            if (placesAutocompleteService.current && optionsArg.input) {
                placesAutocompleteService.current.getQueryPredictions(
                    {
                        ...(sessionToken && autocompleteSession.current
                            ? { sessionToken: autocompleteSession.current }
                            : {}),
                        ...options,
                        ...optionsArg,
                    },
                    (r) => {
                        setIsQueryPredsLoading(false)
                        setQueryPredictions(r || [])
                    },
                )
            }
        }, debounce),
        [debounce],
    )

    useEffect(() => {
        if (window.google && window.google.maps && window.google.maps.places) {
            const buildService = () => {
                placesAutocompleteService.current = new window.google.maps.places.AutocompleteService()
                placesService.current = new window.google.maps.places.PlacesService(
                    document.createElement('div'),
                )

                if (sessionToken) autocompleteSession.current = new window.google.maps.places.AutocompleteSessionToken()
            }

            buildService()
        }
    }, [])

    return {
        placesService: placesService.current,
        autocompleteSessionToken: autocompleteSession.current,
        placesAutocompleteService: placesAutocompleteService.current,
        placePredictions: placeInputValue ? placePredictions : [],
        isPlacePredictionsLoading: isPlacePredsLoading,
        getPlacePredictions: (opt) => {
            const opts = {
                ...opt,
                componentRestrictions: { country: 'de' },
                types: ['street_address', 'city_hall', 'postal_code', 'locality', 'sublocality'],
            }
            if (opt.input) {
                setPlaceInputValue(opt.input)
                setIsPlacePredsLoading(true)
                debouncedPlacePredictions(opts)
                return
            }
            setPlacePredictions([])
            setPlaceInputValue(null)
            debouncedPlacePredictions(opts)
            setIsPlacePredsLoading(false)
        },
        queryPredictions: queryInputValue ? queryPredictions : [],
        isQueryPredictionsLoading: isQueryPredsLoading,
        getQueryPredictions: (opt) => {
            if (opt.input) {
                setQueryInputValue(opt.input)
                setIsQueryPredsLoading(true)
                debouncedQueryPredictions(opt)
                return
            }
            setQueryPredictions([])
            setQueryInputValue(null)
            debouncedQueryPredictions(opt)
            setIsQueryPredsLoading(false)
        },
        refreshSessionToken: () => {
            autocompleteSession.current = new google.maps.places.AutocompleteSessionToken()
        },
    }
}
