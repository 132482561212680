export const isObjectEmpty = (object: any): boolean => {
  if (typeof object !== 'object' || Array.isArray(object) || object === null) {
    return false;
  }
  return Object.keys(object).length === 0 && Object.getPrototypeOf(object) === Object.prototype;
};

// FIXME: Improve this to compare general, deep-nested objects
export const isObjectSame = (object1: any, object2: any): boolean =>
  JSON.stringify(object1) === JSON.stringify(object2);

// returns true if all keys not in the ignoreKeys list have falsy values
export const isObjectValuesFalsy = (obj: { [k: string]: any }, ignoreKeys: string[] = []): boolean =>
  Object.keys(obj).every((key) => ignoreKeys.includes(key) || !obj[key]);

export const isObject = (value) => {
  return (
    value != null && // equality operator checks `undefined` and `null`
    (value.constructor === Object || (!value.constructor && typeof value === 'object'))
  );
};
