import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import useSupercluster from 'use-supercluster'
import GoogleMapReact, { fitBounds } from 'google-map-react'
import usePrevious from '../../../../../services/hook/usePrevious'

const StoreMap = ({
    selectedStore,
    data,
    onSelect,
    zoom,
    setZoom,
    center,
    setCenter,
    isActiveSearch,
    stores,
    selectedLocation,
    defaultCenter,
    isUsedInStore,
    heightHeader,
    offsetY,
}) => {
    if (!window.google) {
        return null
    }

    const mapRef = useRef()
    const [bounds, setBounds] = useState(null)
    const prevStores = usePrevious(stores)

    const Marker = ({ children }) => children

    const points = stores.map((hit) => ({
        type: 'Feature',
        properties: { cluster: false, crimeId: hit.account_id },
        geometry: {
            type: 'Point',
            coordinates: [
                parseFloat(hit._geoloc.lng),
                parseFloat(hit._geoloc.lat),
                hit.specialization_logos.length && hit.specialization_logos[0],
                hit.objectID,
                hit,
            ],
        },
    }))

    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom,
        options: { radius: 75, maxZoom: 20 },
    })

    const handleCalculateMap = () => {
        if (stores.length === 1) {
            return {
                zoom: 13,
                center: [parseFloat(stores[0]._geoloc.lat), parseFloat(stores[0]._geoloc.lng)],
            }
        }

        const bounds = new window.google.maps.LatLngBounds()

        stores.forEach((hit) => {
            bounds.extend(new window.google.maps.LatLng(hit._geoloc.lat, hit._geoloc.lng))
        })

        const ne = bounds.getNorthEast()
        const sw = bounds.getSouthWest()

        const nw = { lat: ne.lat(), lng: sw.lng() }
        const se = { lat: sw.lat(), lng: ne.lng() }

        const { center: customCenter, zoom: customZoom } = fitBounds({
            se: { lat: se.lat, lng: se.lng },
            nw: { lat: nw.lat, lng: nw.lng },
        }, { width: 255, height: 777 })

        return {
            zoom: customZoom > 7 ? customZoom : 7,
            center: customCenter,
        }
    }

    const updateMapByHits = () => {
        const isStoreEmpty = !stores || !stores.length
        if (isStoreEmpty) {
            return
        }

        if (isStoreEmpty && isActiveSearch) {
            setCenter(defaultCenter)
            setZoom(7)
            return
        }

        const {
            zoom: customZoom,
            center: customCenter,
        } = handleCalculateMap()

        if (selectedStore) {
            const indexSelectStore = stores.findIndex((hit) => selectedStore.lat === parseFloat(hit._geoloc.lat)
                && selectedStore.lng === parseFloat(hit._geoloc.lng))

            if (indexSelectStore !== -1 && selectedStore.isFromSlider && !isActiveSearch) {
                setZoom(16)
                setCenter([parseFloat(selectedStore.lat), parseFloat(selectedStore.lng)])
                return
            }

            if (selectedStore.isFromSlider && isActiveSearch) {
                setZoom(13)
                setCenter([parseFloat(selectedStore.lat), parseFloat(selectedStore.lng)])
                return
            }
        }

        if (stores
            && prevStores
            && stores.length === prevStores.length
            && stores[0].objectID === prevStores[0].objectID
            && stores[stores.length - 1].objectID === prevStores[prevStores.length - 1].objectID) {
            return
        }

        setZoom(customZoom)
        setCenter(customCenter)
    }

    useEffect(() => {
        updateMapByHits()
    }, [stores, selectedStore])

    return (
        <div className={'relative overflow-hidden'} style={{ height: `calc(100vh - ${heightHeader}px)` }}>
            <div
                className={'absolute'}
                style={{
                    height: `calc(100vh - ${heightHeader}px + ${offsetY}px)`,
                    width: '100%',
                    top: `-${offsetY}px`,
                }}
            >
                <GoogleMapReact
                    defaultCenter={{
                        lat: data.lat,
                        lng: data.lng,
                    }}
                    defaultZoom={7}
                    center={center}
                    zoom={zoom}
                    yesIWantToUseGoogleMapApiInternals={false}
                    onGoogleApiLoaded={({ map }) => {
                        mapRef.current = map
                    }}
                    onChange={({ zoom, bounds }) => {
                        setZoom(zoom)
                        setBounds([
                            bounds.nw.lng,
                            bounds.se.lat,
                            bounds.se.lng,
                            bounds.nw.lat,
                        ])
                    }}
                >
                    {stores && stores.length < 20 ? (
                        stores.map((store) => {
                            const logoStore = (store.specialization_logos.length && store.specialization_logos[0]) || ''
                            const lat = parseFloat(store._geoloc.lat)
                            const lng = parseFloat(store._geoloc.lng)
                            return (
                                <Marker
                                    key={store.objectID}
                                    lat={lat}
                                    lng={lng}
                                >
                                    <button
                                        className={classnames(`crime-marker ${logoStore}`, {
                                            active: selectedStore && selectedStore.lat === lat && selectedStore.lng === lng,
                                        })}
                                        onClick={() => {
                                            setZoom((prev) => (prev > 11 ? prev : 11))
                                            setCenter([lat, lng])
                                            onSelect({
                                                ...store,
                                                isFromMap: true,
                                            })
                                        }}
                                    />
                                </Marker>
                            )
                        })
                    ) : (clusters.map((cluster) => {
                        const [longitude, latitude, logo, objectId, hit] = cluster.geometry.coordinates
                        const {
                            cluster: isCluster,
                            point_count: pointCount,
                        } = cluster.properties

                        if (isCluster) {
                            return (
                                <Marker
                                    key={`cluster-${cluster.id}`}
                                    lat={latitude}
                                    lng={longitude}
                                >
                                    <div
                                        className={'cluster-marker'}
                                        onClick={() => {
                                            const expansionZoom = Math.min(
                                                supercluster.getClusterExpansionZoom(cluster.id),
                                                20,
                                            )
                                            mapRef.current.setZoom(expansionZoom)
                                            mapRef.current.panTo({ lat: latitude, lng: longitude })
                                            if (!isActiveSearch) {
                                                onSelect({
                                                    lat: latitude,
                                                    lng: longitude,
                                                    isFromMap: true,
                                                    zoom: expansionZoom,
                                                })
                                            }
                                        }}
                                    >
                                        {pointCount}
                                    </div>
                                </Marker>
                            )
                        }

                        return (
                            <Marker
                                key={`crime-${cluster.properties.crimeId}`}
                                lat={latitude}
                                lng={longitude}
                            >
                                <button
                                    className={classnames(`crime-marker ${logo || ''}`, {
                                        active: selectedStore && selectedStore.lat === parseFloat(latitude) && selectedStore.lng === parseFloat(longitude),
                                    })}
                                    onClick={() => {
                                        setZoom((prev) => (prev > 11 ? prev : 11))
                                        setCenter([latitude, longitude])
                                        onSelect({
                                            ...hit,
                                            isFromMap: true,
                                        })
                                    }}
                                />
                            </Marker>
                        )
                    }))}
                </GoogleMapReact>
            </div>
        </div>
    )
}

StoreMap.propTypes = {
    data: PropTypes.any,
    onSelect: PropTypes.any,
    selectedStore: PropTypes.any,
    zoom: PropTypes.any,
    setZoom: PropTypes.any,
    center: PropTypes.any,
    setCenter: PropTypes.any,
    isActiveSearch: PropTypes.any,
    stores: PropTypes.any,
    selectedLocation: PropTypes.any,
    defaultCenter: PropTypes.any,
    isUsedInStore: PropTypes.any,
    heightHeader: PropTypes.any,
    offsetY: PropTypes.any,
}

export default StoreMap
