import React, { useEffect } from 'react'
import { WrappedComponentProps, injectIntl, IntlShape } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import { setOpenFilter } from '../../../../../store/actions/ProductListActions'
import AttributeTranslationService from '../../../../../services/algolia/AttributeTranslationService'
import { ReactComponent as ArrowUp } from '../../../../../../icons/sport2000-icons/arrow-up.svg'
import { ReactComponent as ArrowDown } from '../../../../../../icons/sport2000-icons/arrow-down.svg'

export type Props = {
  attribute: string,
  isOpen: boolean
  intl: IntlShape
  onclick?: () => void
  setIsComponentVisible: React.Dispatch<React.SetStateAction<boolean>>
  isComponentVisible: boolean
  isActiveFilter?: boolean
} & WrappedComponentProps

const PanelButton: React.FC<Props> = ({
  isOpen,
  attribute,
  intl,
  setIsComponentVisible,
  isActiveFilter,
  isComponentVisible,
}: Props) => {
  const dispatcher = useDispatch()
  const activeFilter = useSelector((state) => state['product-list-algolia'].openFilter)
  const LOGO_FILTER_PROP = 'specialization_logos'

  const handleClick = (e) => {
    e.stopPropagation()
    e.preventDefault()

    if (isOpen && isComponentVisible) {
      dispatcher(setOpenFilter({
        openFilter: null,
      }))

      setIsComponentVisible(false)

      return
    }

    setIsComponentVisible(true)
    dispatcher(setOpenFilter({
      openFilter: attribute,
    }))
  }

  useEffect(() => {
    if (attribute === LOGO_FILTER_PROP
      && isActiveFilter
      && (!activeFilter || activeFilter === LOGO_FILTER_PROP)) {
      setIsComponentVisible(true)
      dispatcher(setOpenFilter({
        openFilter: attribute,
      }))
    }
  }, [isActiveFilter])

  return (
    <div className={'filter-panel-select'}>
      <button
        type={'button'}
        className={classnames('filter-buttons-toggle', {
          'is-open': isOpen && isComponentVisible,
        })}
        onClick={(e) => {
          handleClick(e)
        }}
      >
        {AttributeTranslationService.translateAttributeName({ attribute, intl, projectConfigTransKey: 'algoliaStoreFinderTranslation' })}
        <span className={'filter-buttons-toggle-arrow'}>
          {isOpen && isComponentVisible ? (
            <ArrowUp />
          ) : (
            <ArrowDown />
          )}
        </span>
      </button>
    </div>
  )
}

export default injectIntl(PanelButton)
