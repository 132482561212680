import React, { useState } from 'react'
import Proptypes from 'prop-types'
import classnames from 'classnames'
import { FormattedMessage, injectIntl } from 'react-intl'
import Sport2000Logo from '../../../../atoms/sport2000Logo'
import { ReactComponent as LocationIcon } from '../../../../../../icons/sport2000-icons/location.svg'
import { ReactComponent as StoreIcon } from '../../../../../../icons/sport2000-icons/store.svg'
import { ReactComponent as ClockIcon } from '../../../../../../icons/sport2000-icons/clock.svg'
import { ReactComponent as AbsoluteTeamSportLogo } from '../../../../../../icons/sport2000-icons/logos/absoluteteamsport.svg'
import StoreService, { OpeningTimeColor, OpeningTimeTypeNum } from '../../../../../services/algolia/StoreService'
import { ReactComponent as SkiExpertLogo } from '../../../../../../icons/sport2000-icons/logos/SkiExperts_1z_RGB.svg'
import { ReactComponent as OutdoorProfiLogo } from '../../../../../../icons/sport2000-icons/logos/outdoor_profi_CMYK.svg'

const StoreItem = ({
    hit, intl, isFavouriteStore, onClick, selectedStore, onSelect,
}) => {
    const [isOpenTime, setIsOpenTime] = useState(false)
    const [isOpenService, setIsOpenService] = useState(false)

    const handleLogo = (item) => {
        const LOGOS = {
            absolute_run: <Sport2000Logo name={'AbsoluteRun_2line'} alt={'logo absolute run'} width={'157px'} />,
            absolute_teamsport: <AbsoluteTeamSportLogo width={180} />,
            running_expert: <Sport2000Logo name={'RunningExperts'} alt={'logo running expert'} height={'30px'} />,
            teamsport_expert: <Sport2000Logo name={'TeamsportExperts'} alt={'logo teamsport expert'} height={'30px'} />,
            absolute_sport: <AbsoluteTeamSportLogo width={250} />,
            outdoor_expert: <Sport2000Logo name={'OutdoorExperts'} alt={'logo outdoor expert'} height={'30px'} />,
            ski_expert: <SkiExpertLogo width={110} height={30} />,
            outdoorprofi: <OutdoorProfiLogo width={125} height={30} />,
        }

        return (
            LOGOS[item] || null
        )
    }

    const renderStatus = (workingTime) => {
        if (_.isArray(workingTime)) {
            return null
        }

        const statusNumber = StoreService.getStatus(workingTime)
        const status = OpeningTimeTypeNum[statusNumber]

        if (!status) {
            return null
        }

        return (
            <span
                style={{ color: OpeningTimeColor[statusNumber] }}
            >
                {intl.formatMessage({ id: `store.${status}` })}
            </span>
        )
    }

    const renderOpeningTimeToday = (workingTime) => {
        if (_.isArray(workingTime)) {
            return null
        }

        const time = StoreService.getOpeningTimeToday(workingTime)

        if (!time) {
            return null
        }

        return time.map((value, index) => {
            const timeLength = time.length
            return (
                <React.Fragment key={value + index}>
                    {value}
                    {timeLength > 1 && index < timeLength && <br />}
                </React.Fragment>
            )
        })
    }

    return (
        <div
            className={classnames(`store-slider-item ${hit.specialization_logos?.length && hit.specialization_logos[0]}`, {
                active: selectedStore && parseFloat(selectedStore.lat) === parseFloat(hit._geoloc.lat)
                    && parseFloat(selectedStore.lng) === parseFloat(hit._geoloc.lng),
            })}
            onClick={() => onSelect({
                ...hit,
                isFromSlider: true,
            })}
            // onClick={() => onSelect({
            //     lat: parseFloat(hit._geoloc.lat),
            //     lng: parseFloat(hit._geoloc.lng),
            //     logo: hit.specialization_logos?.length && hit.specialization_logos[0],
            //     objectId: hit.objectID,
            // })}
            data-insights-object-id={hit.objectID}
        >
            <div className={'store-slider-item--address store-finder-row'}>
                <div className={'flex w-full'}>
                    <div className={'store-slider-ico-location'}>
                        <LocationIcon width={20} height={20} />
                    </div>
                    <div className={'store-slider-item-address-detail'}>
                        <p>
                            {hit.name && <span className={'font-bold'}>{hit.name}</span>}
                            {' '}
                            <br />
                            {hit.street}
                            {' '}
                            {hit.street_number}
                            <br />
                            {hit.post_code}
                            {' '}
                            {hit.city}
                        </p>
                        {hit.distance && (
                            <div className={'store-slider-distance'}>
                                <span>{hit.distance}</span>
                                {' '}
                                <FormattedMessage id={'storeFinder.distance'} />
                            </div>
                        )}
                    </div>
                </div>
                {hit.specialization_logos.length > 0 && (
                    <div className={'store-slider-item--logo'}>
                        <div className={'flex justify-between items-center'}>
                            {hit.specialization_logos.map((item) => (
                                <div className={'mr-2 flex-1 flex justify-center items-center'} key={item}>
                                    {handleLogo(item)}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>

            <div className={'store-slider-item--determine store-finder-row'}>
                <button
                    type={'button'}
                    aria-label={intl.formatMessage({ id: 'storeFinder.storeDetermine' })}
                    className={'w-full'}
                    onClick={(e) => {
                        e.stopPropagation()
                        onClick(hit)
                    }}
                >
                    <StoreIcon width={20} height={20} />
                    <p className={'mb-0 flex flex-grow text-left'}>
                        <FormattedMessage id={'storeFinder.storeDetermine'} />
                    </p>
                    <div className={'flex justify-end'}>
                        <span className={classnames('select-store-circle', {
                            active: isFavouriteStore,
                        })}
                        />
                    </div>
                </button>
            </div>

            {!_.isArray(hit.opening_hours) && (
                <div
                    className={'store-slider-item--time store-finder-row'}
                >
                    <button
                        type={'button'}
                        aria-label={intl.formatMessage({ id: 'storeFinder.today' })}
                        className={'w-full'}
                    >
                        <ClockIcon width={20} height={20} />
                        {renderStatus(hit.opening_hours)}
                        <span className={'mx-1'}>-</span>
                        <FormattedMessage id={'storeFinder.today'} />
                        <span className={'mr-1'} />
                        {renderOpeningTimeToday(hit.opening_hours)}
                    </button>
                </div>
            )}

            {/* {hit.standard_services.length > 0 && ( */}
            {/*    <div className={classnames('store-slider-item--service store-finder-row', { */}
            {/*        active: isOpenService, */}
            {/*    })} */}
            {/*    > */}
            {/*        <button */}
            {/*            type={'button'} */}
            {/*            aria-label={intl.formatMessage({ id: 'storeFinder.services' })} */}
            {/*            className={'w-full'} */}
            {/*            onClick={(e) => { */}
            {/*                e.stopPropagation() */}
            {/*                setIsOpenService(!isOpenService) */}
            {/*                if (isOpenTime) { */}
            {/*                    setIsOpenTime(false) */}
            {/*                } */}
            {/*            }} */}
            {/*        > */}
            {/*            <StarIcon width={20} height={20} /> */}
            {/*            <FormattedMessage id={'storeFinder.services'} /> */}
            {/*            <ArrowDownIcon width={12} height={12} /> */}
            {/*        </button> */}
            {/*        <div className={'services-container'}> */}
            {/*            {hit.standard_services.map((service) => ( */}
            {/*                <p key={service.id}>{service.name}</p> */}
            {/*            ))} */}
            {/*        </div> */}
            {/*    </div> */}
            {/* )} */}

            <div className={'store-slider-item--actions store-finder-row'}>
                {hit.url && (
                    <a
                        className={'btn btn-secondary'}
                        href={hit.url}
                        target={'_blank'}
                        rel={'noreferrer'}
                    >
                        <FormattedMessage id={'storeFinder.sliderDetailBtn'} />
                    </a>
                )}
                <a
                    href={`https://maps.google.be/maps?daddr=${hit._geoloc.lat},${hit._geoloc.lng}`}
                    className={'btn btn-secondary'}
                    target={'_blank'}
                    rel={'noreferrer'}
                >
                    <FormattedMessage id={'storeFinder.routeBtn'} />
                </a>
            </div>
        </div>
    )
}

StoreItem.propTypes = {
    hit: Proptypes.any,
    intl: Proptypes.any,
    isFavouriteStore: Proptypes.any,
    onClick: Proptypes.any,
    selectedStore: Proptypes.any,
    onSelect: Proptypes.any,
}

export default injectIntl(StoreItem)
