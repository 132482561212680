import _ from 'lodash';

export default class FilterStoreService {
  public static getFacetItemsByAttribute(facets, attribute, facetDisplay) {
    const items = [];
    const facet = facets[attribute];

    if (!facet) {
      return items;
    }

    for (const [key, value] of Object.entries(facet)) {
      items.push({
        label: key,
        count: value,
        value: key,
        attribute,
      });
    }

    if (facetDisplay) {
      const sortState = facetDisplay[attribute].sortRemainingBy || '';
      const facetDisplayOrder = facetDisplay[attribute].order;

      if (sortState === 'alpha') {
        return _.orderBy(items, ['label']);
      }

      if (sortState === 'count') {
        return _.orderBy(items, ['count'], ['desc']);
      }

      if (sortState === 'hidden' && facetDisplayOrder) {
        const sortedItems = [];
        facetDisplayOrder.forEach((facetValue) => {
          const index = items.findIndex((item) => item.label === facetValue);

          if (index !== -1) {
            sortedItems.push(items[index]);
          }
        });
        return sortedItems;
      }
    }

    return items;
  }

  public static updateCurrentRefinement(currentRefinement, item) {
    const cloneCrm = currentRefinement.concat();
    const index = cloneCrm.findIndex((val) => val.value === item.value);

    console.log('ABCCCC', currentRefinement, item);

    if (index !== -1) {
      cloneCrm.splice(index, 1);
      return cloneCrm;
    }

    cloneCrm.push(item);
    return cloneCrm;
  }

  public static formatFacetFilter(currentRefinement) {
    const result = [];
    const cloneCrm = currentRefinement.concat();

    if (!cloneCrm?.length) {
      return result;
    }

    let strFacet = '';

    currentRefinement.forEach((item) => {
      strFacet = `${item.attribute}:${item.value}`;
      result.push(strFacet);
    });

    const groupedFacetFilters = result.reduce((groups, filter) => {
      // Ensure the filter is a string and in the correct format
      if (typeof filter === 'string' && filter.includes(':')) {
        const [identifier] = filter.split(':'); // Extract the identifier before ":"
        if (!groups[identifier]) {
          groups[identifier] = [];
        }
        groups[identifier].push(filter);
      }
      return groups;
    }, {});

    // Convert the grouped object into the AND-compatible structure
    const andFacetFilters = Object.values(groupedFacetFilters);

    return andFacetFilters;
  }
}
