import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connectStateResults } from 'react-instantsearch-dom'
import { Context } from '../../../../types/frontastic'
import SearchSuggestLayout from '../../SearchAlgolia/SearchSuggestLayout'
import SearchLocalStorage from '../../SearchAlgolia/SearchLocalStorage'
import SearchLinkLayout from '../../SearchAlgolia/SearchLinkLayout'
import { useRouter } from "next/router";

type Props = {
    context: Context,
    searchFAQ: object[]
    searchResults: any
    node: any
    searching: boolean
}

const EmptySearch: React.FC<Props> = ({
    context, searchFAQ, searchResults, node, searching,
}) => {
    const router = useRouter();

    if (node.nodeType !== 'search' || searchResults.nbHits || searching) {
        return null
    }

    const getQuery = () => {
        return decodeURIComponent(`${router.query.query ?? ''}`)
    }

    return (
        <div className={'search-page'}>
            <h1 className={'search-page-headline'}>
                <FormattedMessage id={'search.emptyResult.empty'} values={{ query: getQuery() }} />
            </h1>

            <div className={'search-page--wrapper'}>
                <SearchLocalStorage node={node} />
                <SearchSuggestLayout context={context} />
                <SearchLinkLayout searchFAQ={searchFAQ} />
            </div>
        </div>
    )
}

export default connectStateResults(EmptySearch)
