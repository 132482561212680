import { useEffect, useRef } from 'react'

export default (value: null | string) => {
    const ref = useRef<any>(null)

    useEffect(() => {
        ref.current = value
    }, [value])

    return ref.current
}
