import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import IconButton from '../../../../atoms/button/IconButton'
import { ReactComponent as CloseIcon } from '../../../../../../icons/sport2000-icons/close.svg'
import { ReactComponent as SearchIcon } from '../../../../../../icons/sport2000-icons/search.svg'

type Props = {
    items?: Array<Item>,
    setFilterList: (any) => void,
}

export type Item = {
    count: number;
    isRefined: boolean;
    label: string;
    value: string[]
}

const SearchFilter: React.FC<Props> = ({
    items, setFilterList,
}) => {
    if (!items || !items.length) {
        return null
    }

    const [searchValue, setSearchValue] = useState('')
    const MIN_FILTER_ITEM_TO_SHOW_SEARCH = 3

    useEffect(() => {
        if (searchValue.trim() === '') {
            setFilterList(items)
        }
        const listSearchFilter = items.filter((item) => item.label.toLowerCase().includes(searchValue.toLowerCase()))
        setFilterList(listSearchFilter)
    }, [searchValue, items])

    return (
        <>
            {(items.length > MIN_FILTER_ITEM_TO_SHOW_SEARCH) && (
                <div className={'px-4'}>
                    <div className={'relative'}>
                        <div className={'form-filter-search'}>
                            <input
                                type={'search'}
                                name={'filter-search'}
                                id={'filter-search'}
                                className={'search-input'}
                                placeholder={'Search for...'}
                                value={searchValue}
                                onChange={(e) => {
                                    setSearchValue(e.target.value)
                                }}
                            />
                            <label
                                htmlFor={'filter-search'}
                            >
                                <FormattedMessage id={'filters.search'} />
                            </label>
                        </div>
                        {searchValue && (
                            <IconButton
                                className={'filter-close-button'}
                                type={'button'}
                                ariaLabel={'closeIcon'}
                                onClick={() => {
                                    setSearchValue('')
                                }}
                                icon={<CloseIcon width={12} height={12} />}
                            />
                        )}
                        <IconButton
                            className={'filter-search-button'}
                            type={'button'}
                            ariaLabel={'searchIcon'}
                            icon={<SearchIcon width={20} height={20} />}
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default SearchFilter
